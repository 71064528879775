<script setup>
import UserSettingsAccount from '@/views/pages/users/UserSettingsAccount.vue'
import UserSettingsSecurity from '@/views/pages/users/UserSettingsSecurity.vue'
import { onBeforeMount } from 'vue'



const props = defineProps({
  id: {
    type: String,
    default: null,
    required: false,
  },
  tab: {
    type: String,
    default: 'account',
  },
})

definePage({
  meta: {
    action: 'update',
    subject: 'master.user',
  },  
})

const route = useRoute('users')
const router = useRouter()

const activeTab = computed({
  get: () => route.params.tab,
  set: () => route.params.tab,
})

const userData = ref({})


if (route.params?.id) {
  userData.value = await useApi(`/masters/users/${ route.params?.id }`)?.data
}


// tabs
const tabs = [
  {
    title: 'Account',
    icon: 'tabler-users',
    tab: 'account',
  },
  {
    title: 'Security',
    icon: 'tabler-lock',
    tab: 'security',
  },
]

onBeforeMount(() => {
  if (route.params?.id && !tabs.find(item => item.tab === route.params.tab)) {
    router.push({ name: 'user-tab', params: { tab: 'account', id: route.params?.id } })
  }
})
</script>

<template>
  <div>
    <div v-if="route.params?.id">
      <VTabs
        v-model="activeTab"
        class="v-tabs-pill"
      >
        <VTab
          v-for="item in tabs"
          :key="item.icon"
          :value="item.tab"
          :to="{ name: 'app-user-tab', params: { tab: item.tab,id:route.params.id} }"
        >
          <VIcon
            size="20"
            start
            :icon="item.icon"
          />
          {{ item.title }}
        </VTab>
      </VTabs>
  
      <VWindow
        v-model="activeTab"
        class="mt-6 disable-tab-transition"
        :touch="false"
      >
        <!-- Account -->
        <VWindowItem value="account">
          <UserSettingsAccount :user-data="userData" />
        </VWindowItem>
  
        <!-- Security -->
        <VWindowItem value="security">
          <UserSettingsSecurity :user-data="userData" />
        </VWindowItem>
      </VWindow>
    </div>
    <div v-else-if="!route.params?.id">
      <UserSettingsAccount :user-data="null" />
    </div>
    <VCard v-else>
      <VCardTitle class="text-center">
        <VRow align="center">
          <VCol>
            <VIcon
              icon="tabler-alert-triangle"
              size="24"
            />
            User Not Found
          </VCol>
        </VRow>
        <!-- go back -->
        <VBtn
          color="primary"
          class="mt-3"
          @click="router.go(-1)"
        >
          Go Back
        </VBtn>
      </VCardTitle>
    </VCard>
  </div>
</template>
